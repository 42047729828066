import { useState } from 'react';
import ReactModal from 'react-modal';
import './App.css';

const extra1 = [79800];
const extra2 = [79399, 79799, 80199, 79400, 80200, 79401, 79801, 80201];
const extra3 = [77795, 78195, 78595, 78995, 79395, 79795, 80195, 80595, 80995, 81395, 81795, 77796, 78196, 78596, 78996, 79396, 79796, 80196, 80596, 80996, 81396, 81796, 77797, 78197, 78597, 78997, 79397, 79797, 80197, 80597, 80997, 81397, 81797, 77798, 78198, 78598, 78998, 79398, 79798, 80198, 80598, 80998, 81398, 81798, 77799, 78199, 78599, 78999, 80599, 80999, 81399, 81799, 77800, 78200, 78600, 79000, 80600, 81000, 81400, 81800, 77801, 78201, 78601, 79001, 80601, 81001, 81401, 81801, 77802, 78202, 78602, 79002, 79402, 79802, 80202, 80602, 81002, 81402, 81802, 77803, 78203, 78603, 79003, 79403, 79803, 80203, 80603, 81003, 81403, 81803, 77804, 78204, 78604, 79004, 79404, 79804, 80204, 80604, 81004, 81404, 81804, 77805, 78205, 78605, 79005, 79405, 79805, 80205, 80605, 81005, 81405, 81805];

const getColor = (x,y) => {
  if (x >= -39 && x <= 40 && y >=-39 && y <= 40) {
    return "Green";
  }
  if (x >= -79 && x <= 80 && y >=-79 && y <= 80) {
    return "Blue";
  }
  if (x >= -119 && x <= 120 && y >=-119 && y <= 120) {
    return "Yellow";
  }
  if (x >= -159 && x <= 160 && y >=-159 && y <= 160) {
    return "Grey";
  }
  return "Orange";
}

const getExtraArea = (id) => {
  if (extra1.includes(id)) {
    return 1;
  }
  if (extra2.includes(id)) {
    return 2;
  }
  if (extra3.includes(id)) {
    return 3;
  }
  return 0;
};

function App() {
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  const [ selectedId, setSelectedId ] = useState(null);

  const onClick = (e) => {
    const id = e.target.attributes["data-id"].value;
    setSelectedId(id);
    setIsModalOpen(true);
  }

  const close = () => (
    <svg onClick={() => setIsModalOpen(false)} style={{ cursor: "pointer" }} fill="#000000" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="60px" height="60px">    <path d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z"/></svg>
  );

  return (
    <div>
      <div className="map-partyland" onClick={onClick}>
        {[ ...Array(400).keys() ].map(x => (
          <div className="row" key={x}>{
            [ ...Array(400).keys() ].map(y => {
              const id = (x-1)*400 + y;
              const coordX = x-200;
              const coordY = y-200;
              const color = getColor(coordX,coordY).toLowerCase();
              const extra = getExtraArea(id);

              return (<span key={y} data-id={id} className={`cell cell--${color} ${!extra ? "" : `cell--extra-${extra}`}`}></span>);
            })
          }</div>
        ))}
      </div>
      <ReactModal
        ariaHideApp={false}
        isOpen={isModalOpen}
        style={{ position: "relative" }}
      >
        <span style={{ position: "absolute", right: 0, top: 0 }}>{close()}</span>
        <iframe style={{ width: "100%", height: "100%", border: "none" }} src={`https://opensea.io/assets/ethereum/0x4791cbe40ca5a839c07d498d0bfd84ad3c4bbb08/${selectedId}?embed=true`} />
      </ReactModal>
    </div>
  );
}

export default App;
